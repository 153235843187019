<template>
    <div>
        Halaman tidak ditemukan
    </div>
</template>

<script>
    export default {
        name: 'Maintenance',
        metaInfo: {
            title: 'Halaman Tidak Ditemukan',
            titleTemplate: '%s - Griya Belajar'
        },
    }
</script>