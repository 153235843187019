import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VuePageTransition from 'vue-page-transition'

Vue.config.productionTip  = false
Vue.prototype.$http       = axios
Vue.prototype.$apiconfig  = process.env.VUE_APP_APIHOST

// Vue Use
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePageTransition)

// Add Global Auth Token
const token = localStorage.getItem('auth_token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
