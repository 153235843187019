import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Promotion from '../views/Promotion.vue'
import Maintenance from '../views/Maintenance.vue'
import Notfound from '../views/Notfound.vue'
import TermConditions from '../views/TermConditions.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: TermConditions,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/promotion/:slug',
    name: 'promotion',
    component: Promotion,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: Notfound,
    meta: {
      transition: 'fade'
    }
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router