<template>
    <div>
        <!-- ======= Header ======= -->
        <header id="header" class="fixed-top header-scrolled-fix">
            <div class="container d-flex align-items-center">

                <a href="" class="logo me-auto"><img src="assets/img/favicon-griyabelajar.png" alt=""
                        class="img-fluid"><span class="logo me-auto logo-text text-white" style="margin-left:20px !important">GriyaBelajar</span></a>

                <nav id="navbar" class="navbar">
                    <ul>
                        <li><a class="text-center getstarted scrollto" href="">Beranda</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav><!-- .navbar -->
            </div>
        </header>

        <main id="main" v-if="fetchBanner">
            <p class="text-center">Loading...</p>
        </main>

        <main id="main" v-if="!fetchBanner">
            <section id="promotion" class="about section-bg" style="padding-top:30px !important; padding-bottom:10px !important; border-bottom-left-radius: 20px !important;border-bottom-right-radius: 20px !important;">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                    </div>
                </div>
            </section>

            <section id="privilage" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="section-title m-b-0">
                        <h5>{{data_banner.name}}</h5>
                    </div>

                    <div class="row" style="margin-top:-30px !important">
                        <div class="col-md-8 col-sm-12 mx-auto hero-img" data-aos="zoom-in" data-aos-delay="200">                    
                            <div class="promotion-banner">
                                <img v-if="data_banner.cover" style="width:100%" :src="data_banner.cover">
                                <img v-if="!data_banner.cover" style="width:100%" src="assets/img/default-promo.png">
                            </div>
                        </div>
                    </div>

                    <div class="row content mt-5 section-bg" style="padding:20px;border-radius: 15px;margin:0px">
                        <div class="col-lg-12">
                            <div v-html="data_banner.description">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" class="cta">
                <div class="container" data-aos="zoom-in">

                    <div class="row">
                        <div class="col-lg-9 text-center text-lg-start">
                            <h3>Mari Bergabung dan menjadi bagian dari kami!</h3>
                            <p>Yuk mulai belajar hari ini dan bersiap untuk bersaing dengan jutaan peserta lainnya!</p>
                        </div>
                        <div class="col-lg-3 cta-btn-container text-center">
                            <a class="cta-btn align-middle" :href="frontend_url + '#/signup'">Daftar Sekarang</a>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ======= Frequently Asked Questions Section ======= -->
            <section id="faq" class="faq section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>Frequently Asked Questions (FAQ)</h2>
                        <p>Pertanyaan seputar tentang kami</p>
                    </div>

                    <div class="faq-list">
                        <ul>
                            <li v-for="(item, index) in listFaq" :key="index" data-aos="fade-up" data-aos-delay="100">
                                <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse"
                                    class="collapse" :data-bs-target="'#faq-list-'+item.id">{{item.title}} <i
                                        class="bx bx-chevron-down icon-show"></i><i
                                        class="bx bx-chevron-up icon-close"></i></a>
                                    <div :id="'faq-list-'+item.id" class="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            {{item.contents}}
                                        </p>
                                    </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </section>
        </main>

        <!-- ======= Footer ======= -->
        <footer id="footer" v-if="!fetchBanner">
            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-4 col-md-6 footer-contact">
                            <div class="text-center">
                                <a href="#hero" class="logo me-auto"><img style="width:70%" src="assets/img/logo.png"
                                        alt="" class="img-fluid"></a>
                            </div>
                            <p class="mt-3">
                                {{web_profile.address}}
                                <br><br />
                                <strong>Nomor Hanphone : </strong>{{web_profile.phone}}<br />
                                <strong>Whatsapp : </strong> <a
                                    :href="'https://web.whatsapp.com/send?phone='+web_profile.whatsapp"
                                    target="blank">+{{web_profile.whatsapp}} (WhatsApp)</a><br />
                                <strong>Alamat Email : </strong> <a :href="'mailto:'+web_profile.email"
                                    target="blank">{{web_profile.email}}</a><br />
                            </p>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <h4>Tentang Kami</h4>
                            <p>{{web_profile.about}}</p>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <h4>Sosial Media</h4>
                            <p>Yuk, Join dengan sosial media kami dan dapatkan informasi-informasi menarik lainya dari
                                sosial media kami.</p>
                            <div class="social-links mt-3">
                                <a v-if="web_profile.url_youtube" :href="web_profile.url_youtube"
                                    target="blank" class="youtube"><i class="bx bxl-youtube"></i></a>
                                <a v-if="web_profile.url_facebook" :href="web_profile.url_facebook"
                                    target="blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                                <a v-if="web_profile.url_instagram" :href="web_profile.url_instagram"
                                    target="blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="footer-bottom clearfix">
                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong><span>GriyaBelajar</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Dibuat dengan <span style="color: #fff;">&#10084;</span> oleh GriyaBelajar.
                    </div>
                </div>
            </div>
        </footer><!-- End Footer -->
    </div>
</template>

<script>
    export default {
        name: 'Home',
        metaInfo: {
            title: 'Promo',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                fetchFaq        : false,
                listFaq         : [],
                fetchBanner     : false,
                data_banner     : [],
                fetchProfile    : false,
                web_profile     : [],
                frontend_url    : process.env.VUE_APP_FRONTEND,
                backend_url     : process.env.VUE_APP_APIHOST
            }
        },
        mounted() {
            let externalScript = document.createElement('script')
            externalScript.setAttribute('src', 'assets/js/main.js')
            document.head.appendChild(externalScript)
        },
        created() {
            this.getProfile();
            this.getBannerDetail();
            this.getFaq();
        },
        methods: {
            getBannerDetail: function() {
                let uri         = this.backend_url+'website/banner/detail';

                this.fetchBanner  = true;                
                this.$http.get(uri, {
                    params : {
                        code : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.fetchBanner  = false;
                        this.data_banner  = res.data.data;
                    } else {
                        this.$router.push({ name: 'home' })                        
                    }
                });
            },
            getProfile: function() {
                let uri         = this.backend_url+'website/profile';

                this.fetchProfile  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchProfile  = false;                
                    this.web_profile   = res.data.data;
                });
            },
            getFaq: function() {
                let uri         = this.backend_url+'website/faq/list';

                this.fetchFaq  = true;                
                this.$http.get(uri).then(res => {
                    this.fetchFaq  = false;                
                    this.listFaq   = res.data.data;
                });
            }
        }
    }
</script>